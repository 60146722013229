<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer" slim>
        <form action="#" method="post" name="login_form" class="form_default mt-4" @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider name="e-mail" rules="required|email" slim v-slot="{ errors, classes, required }">
                <div class="form-group">
                    <label for="recovery_email"> E-mail </label>
                    <input type="email" id="recovery_email" name="recovery_email" class="form-control" autocomplete="current-email" v-model="form_email" :class="classes">
                    <small v-show="errors[0]" class="text-danger">{{ errors[0] }}</small>
                </div>
            </ValidationProvider>
            <div class="d-flex justify-content-end mt-4 mb-4">
                <a href="#" class="login-lost-password" title="Lembrou da senha? Acesse" @click.prevent="hide()">Lembrou da senha? Acesse</a>
            </div>
            <recaptcha ref="recaptcha" :data-sitekey="$wdconfig.default.recaptcha_site" @error="recaptcha_error()"/>
            <FormMessage :msg="msg_form" :type="msg_form_type"></FormMessage>
            <button type="submit" class="btn-default btn-square w-100">{{ sending ? 'Enviando' : 'Enviar' }}</button>
        </form>
    </ValidationObserver>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import FormMessage from "~/components/FormMessage";
    import utils from "~/mixins/utils";

    export default {
        components: {
            FormMessage,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [
            utils
        ],
        props: {
            email: {
                default: ''
            }
        },
        data: function () {
            return {
                sending: false,
                token: '',
                form_email: '',
                msg_form: '',
                msg_form_type: '',
            }
        },
        methods: {
            recaptcha_error(e) {
                this.msg = 'Erro ao gerar recaptcha'
                console.error('recaptcha: ', e)
            },
            async onSubmit() {
                const widget_id = this.getRecaptchaWidgetId('recaptcha')
                try {
                    let token = await this.$recaptcha.getResponse(widget_id);
                    this.sending = true;
                    const params = new URLSearchParams();
                    params.append('type_project', 'site');
                    params.append('email', this.email);
                    params.append('g-recaptcha-response', token);
                    this.$axios.$post(`index.php?obj=auth&method=sendLinkChangePassword`, params)
                        .then((response) => {
                            if (response.status !== 'success') {
                                throw response;
                            }
                            this.msg_form = response.message;
                            this.msg_form_type = 'success';
                            this.$recaptcha.reset(widget_id);
                        })
                        .catch((error) => {
                            console.error(error)
                            this.msg_form = 'Erro ao enviar recuperação';
                            if (error.message || error.msg) {
                                this.msg_form = error.message || error.msg;
                            }
                            this.$recaptcha.reset(widget_id);
                            this.msg_form_type = 'error';
                        })
                } catch (error) {
                    console.error(error)
                    this.msg_form = 'Não foi enviar o esqueci senha';
                    this.msg_form_type = 'error';
                    this.$recaptcha.reset(widget_id);
                }
            },
            hide() {
                this.$emit("hide", true);
            }
        },
        watch: {
            email(newvalue) {
                this.form_email = newvalue
            },
            form_email(newvalue, oldvalue) {
                if (oldvalue !== '' && newvalue !== oldvalue) {
                    this.$emit("update-email", newvalue);
                }
            },
            sending(newvalue) {
                if (newvalue) {
                    this.msg_form = ''
                }
            },
            msg_form(newvalue) {
                if (newvalue !== '') {
                    this.sending = false
                }
            }
        }
    }
</script>
