<template>
    <div>
        <Breadcrumb :breadcrumb="breadcrumb"/>
        <div class="login pt-4 pb-5">
            <b-container>
                <b-row>
                    <b-col cols="12" lg="6" class="mb-3 mx-auto">
                        <FormLogin/>
                    </b-col>
<!--                    <b-col cols="12" lg="6">-->
<!--                        <FormPreRegister/>-->
<!--                    </b-col>-->
                </b-row>
                <div class="text-center mt-4 mb-4">
                    <small>Este site é protegido pelo reCAPTCHA e pelo Google
                        <a href="https://policies.google.com/privacy">Política de Privacidade</a> e
                        <a href="https://policies.google.com/terms">Termos de serviço</a>.
                    </small>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import utils from '~/mixins/utils'
import Breadcrumb from '~/components/Breadcrumb';
import FormLogin from '~/components/Form-login';
import FormPreRegister from '~/components/Form-pre-register.vue';
export default {
    head() {
        return {
            title: 'Acesse sua conta',
            meta: [
                {
                    hid: `twitter:card`,
                    name: 'twitter:card',
                    content: "summary_large_image"
                },
                {
                    hid: `og:title`,
                    property: 'og:title',
                    content: 'Acesse sua conta'
                },
                {
                    hid: `og:url`,
                    property: 'og:url',
                    content: `${this.$host}${this.$route.fullPath}`
                },
                {
                    hid: `og:image`,
                    property: 'og:image',
                    content: this.imageUrl(this.$wdconfig.default.logo)
                },
                {
                    hid: 'og:image:height',
                    property: 'og:image:height',
                    content: '315'
                },
                {
                    hid: 'og:image:width',
                    property: 'og:image:width',
                    content: '600'
                },
            ]
        }
    },
    mixins: [
        utils
    ],
    components:{
        Breadcrumb,
        FormLogin,
        FormPreRegister
    },
    computed: {
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Home',
                    link: '/'
                }
            ];
            breadcrumb.push({
                name: 'Acesse sua conta',
                link: null
            });
            return breadcrumb;
        },
    }
}
</script>
