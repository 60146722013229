<template>
    <div class="input-group">
        <input :type="type" :id="id" :name="id" class="form-control login-input-field" :autocomplete="autocomplete" :class="classes" :value="value" v-on:input="updateValue($event.target.value)" :autofocus="autofocus" ref="input">
        <div class="input-group-append">
            <button class="login-btn_view_password" type="button" title="Visualizar senha" @click.prevent="switchVisibility">
                <img :src="icon" :alt="icon_title" :title="icon_title">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        autocomplete: {
            default: ''
        },
        classes: {},
        id: {
            required: true
        },
        value: {},
        autofocus: {
            default: false
        },
    },
    data: () => ({
        password: '',
        type: 'password',
        icon: '/img/eye.svg',
        icon_title: 'Visualizar senha'
    }),
    mounted() {
        if (this.autofocus) {
            setTimeout(() => {
                this.$refs.input.focus()
            }, 10)
        }
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value)
        },
        switchVisibility() {
            if (this.type === 'password') {
                this.type = 'text'
                this.icon = '/img/eye-hide.svg'
                this.icon_title = 'Visualizar senha'
            } else {
                this.type = 'password'
                this.icon = '/img/eye.svg'
                this.icon_title = 'Ocultar senha'
            }
        }
    }
}
</script>
