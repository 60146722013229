var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-form"},[_c('div',{staticClass:"login-title"},[_vm._v(" Não tem cadastro? ")]),_vm._v(" "),_c('div',{staticClass:"login-subtitle"},[_vm._v(" Cadastre-se agora ")]),_vm._v(" "),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form_default mt-4",attrs:{"action":"#","method":"post","name":"register_form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"nome","rules":"required|fullname","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var required = ref.required;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"login-labels",attrs:{"for":"register_name"}},[_vm._v(" Nome ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.form.name),expression:"register.form.name"}],staticClass:"form-control login-input-field",class:classes,attrs:{"type":"text","id":"register_name","name":"register_name"},domProps:{"value":(_vm.register.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register.form, "name", $event.target.value)}}}),_vm._v(" "),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var required = ref.required;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"login-labels",attrs:{"for":"register_email"}},[_vm._v(" E-mail ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.form.email),expression:"register.form.email"}],staticClass:"form-control login-input-field",class:classes,attrs:{"type":"email","id":"register_email","name":"register_email"},domProps:{"value":(_vm.register.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register.form, "email", $event.target.value)}}}),_vm._v(" "),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"mt-5 mb-4"},[_c('button',{staticClass:"btn-default btn-square w-100 mt-3",attrs:{"type":"submit"}},[_vm._v("Fazer cadastro")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }