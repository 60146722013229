<template>
    <div class="breadcrumb mb-0 d-none d-md-block">
        <b-container>
            <div class="d-flex">
                <div v-for="(item, index) in breadcrumb" :key="index">
                    <a :title="item.name" :href="item.link" class="ml-1 breadcrumb-link d-flex">
                        <p class="mb-0" :class="index == breadcrumb.length-1 ? 'breadcrumb-link-current' : ''">{{ item.name | truncate(40) }}</p>
                        <p class="ml-1 mb-0">/</p>
                    </a>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import utils from '~/mixins/utils'
export default {
    mixins: [
        utils
    ],
    props: [
        'breadcrumb'
    ]
}
</script>
