<template>
    <div class="login-form">
        <div class="login-title"> Não tem cadastro? </div>
        <div class="login-subtitle"> Cadastre-se agora </div>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form action="#" method="post" name="register_form" class="form_default mt-4" @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider name="nome" rules="required|fullname" slim v-slot="{ errors, classes, required }">
                    <div class="form-group">
                        <label for="register_name" class="login-labels"> Nome </label>
                        <input type="text" id="register_name" name="register_name" class="form-control login-input-field" v-model="register.form.name" :class="classes">
                        <small v-show="errors[0]" class="text-danger">{{ errors[0] }}</small>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="e-mail" rules="required|email" slim v-slot="{ errors, classes, required }">
                    <div class="form-group">
                        <label for="register_email" class="login-labels"> E-mail </label>
                        <input type="email" id="register_email" name="register_email" class="form-control login-input-field" v-model="register.form.email" :class="classes">
                        <small v-show="errors[0]" class="text-danger">{{ errors[0] }}</small>
                    </div>
                </ValidationProvider>
                <div class="mt-5 mb-4">
                    <button type="submit" class="btn-default btn-square w-100 mt-3">Fazer cadastro</button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data: function () {
        return {
            token: '',
            register: {
                form: {
                    name: '',
                    email: ''
                },
                action: '/cadastro',
                sending: false
            }
        }
    },
    methods: {
        async onSubmit() {
            this.register.sending = true;
            for (let key in this.register.form) {
                sessionStorage.setItem("register_" + key, this.register.form[key]);
            }
            if (this.$route.query.next) {
                window.location.href = this.$route.query.next;
            } else {
                window.location.href = '/cadastro';
            }
        }
    }
}
</script>
