<template>
    <div class="login-form">
        <transition name="expand">
            <ValidationObserver v-slot="{ handleSubmit }" slim v-show="!recovery">
                <div class="login-subtitle">
                    {{ registered ? 'Para continuar, insira sua senha' : 'Para continuar, insira seu e-mail' }}
                </div>
                <form action="#" method="post" name="login_form" class="form_default mt-4" @submit.prevent="handleSubmit(onSubmit)">
                    <ValidationProvider name="e-mail" rules="required|email" slim v-slot="{ errors, classes, required }">
                        <div class="form-group">
                            <label for="login_email" class="login-labels"> E-mail </label>
                            <input type="email" id="login_email" name="login_email_" :autofocus="!email" class="form-control login-input-field" autocomplete="current-email" v-model="email" :class="classes">
                            <small v-show="errors[0]" class="text-danger">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <template v-if="registered">
                        <ValidationProvider name="senha" rules="required" slim v-slot="{ errors, classes, required }">
                            <div class="form-group">
                                <label for="login_password" class="login-labels"> Senha </label>
                                <InputPassword id="login_password" :classes="classes" :autofocus="registered" v-model="password" autocomplete="current-password"/>
                                <small v-show="errors[0]" class="text-danger">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex justify-content-between mt-4 mb-4 flex-column flex-md-row">
                            <div class="login-form-check">
                                <input class="login-form-check-input" type="checkbox" value="" id="defaultCheck1">
                                <label class="login-form-check-label" for="defaultCheck1" v-model="remember">
                                    Manter conectado
                                </label>
                            </div>
                            <a href="#" class="login-lost-password" title="Esqueceu sua senha?" @click.prevent="recovery = true">Esqueceu sua senha?</a>
                        </div>
                    </template>
                    <recaptcha ref="recaptcha" :data-sitekey="$wdconfig.default.recaptcha_site" @error="recaptcha_error()"/>
                    <FormMessage :msg="msg_form" :type="msg_form_type"></FormMessage>
                    <button type="submit" class="btn-default btn-square w-100">
                        {{ sending ? 'Enviando' : registered ? 'ACESSAR CONTA' : 'CONTINUAR' }}
                    </button>
                </form>
            </ValidationObserver>
        </transition>

        <transition name="expand">
            <FormPasswordRecovery v-show="recovery" :email="email" @update-email="updateEmailPasswordRecovery" @hide="hidePasswordRecovery"/>
        </transition>
    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import FormMessage from "~/components/FormMessage";
    import InputPassword from "~/components/Input-password.vue";
    import Cookie from 'js-cookie'
    import FormPasswordRecovery from "~/components/Form-password-recovery";
    import utils from "~/mixins/utils";

    export default {
        middleware: 'notAuthenticated',
        components: {
            FormPasswordRecovery,
            FormMessage,
            ValidationProvider,
            ValidationObserver,
            InputPassword
        },
        mixins: [
            utils
        ],
        data: function () {
            return {
                registered: false,
                registered_email: '',
                recovery: false,
                sending: false,
                active: 'login',
                token: '',
                email: '',
                password: '',
                remember: false,
                msg_form: '',
                msg_form_type: ''
            }
        },
        async mounted() {
            this.$nuxt.$on('loginData', (data) => {
                if (typeof data === 'object') {
                    this.email = data.email
                    this.registered = true
                    this.registered_email = data.email
                }
            })
        },
        methods: {
            recaptcha_error(e) {
                this.msg = 'Erro ao gerar recaptcha'
                console.error('recaptcha: ', e)
            },
            async onSubmit() {
                if (this.registered && this.email === this.registered_email) {
                    await this.onSubmitLogin()
                } else {
                    await this.onSubmitEmail()
                }
            },
            async onSubmitEmail() {
                try {
                    this.sending = true;
                    const params = new URLSearchParams();
                    params.append('metodo', 'valida_email');
                    params.append('email', this.email);
                    return this.$axios.$post(`?p=erp_pessoas_api_v1`, params)
                        .then((response) => {
                            if (typeof response === 'object' && Array.isArray(response)) {
                                this.registered = response[0]['msg'] === 'sucesso'
                                if (this.registered) {
                                    this.registered_email = this.email
                                } else {
                                    sessionStorage.setItem("register_email", this.email);
                                    if (this.$route.query.next) {
                                        if (this.$route.query.register === 'no') {
                                            this.$router.push(this.$route.query.next)
                                        } else {
                                            this.$router.push(`/cadastro?next=${this.$route.query.next}`)
                                        }
                                    } else {
                                        this.$router.push('/cadastro')
                                    }
                                }
                            } else {
                                this.registered = false
                            }
                            this.sending = false;
                        })
                        .catch((error) => {
                            this.msg_form_type = 'error';
                            this.sending = false;
                            // this.$recaptcha.reset();
                        })
                } catch (error) {
                    this.msg_form = 'Não foi possível autenticar';
                    this.msg_form_type = 'error';
                }
            },
            async onSubmitLogin() {
                const widget_id = this.getRecaptchaWidgetId('recaptcha')
                try {
                    let token = await this.$recaptcha.getResponse(widget_id);
                    this.sending = true;
                    const params = new URLSearchParams();
                    params.append('type_autenticate', 'email');
                    params.append('type_project', 'site');
                    params.append('email', this.email);
                    params.append('password', this.password);
                    params.append('senha', this.password);
                    params.append('remember', this.remember);
                    params.append('g-recaptcha-response', token);
                    return this.$axios.$post(`/?p=login&obj=loginJwt&type=json`, params)
                        .then((response) => {
                            if (response.status === 'success') {
                                this.$axios.setToken(response.jwt, 'Bearer');
                                this.postLogin(response);
                            } else {
                                this.msg_form = response.msg;
                                this.msg_form_type = 'error';
                            }
                            this.$recaptcha.reset(widget_id);
                        })
                        .catch((error) => {
                            this.msg_form = error.response.data.msg;
                            this.msg_form_type = 'error';
                            this.$recaptcha.reset(widget_id);
                        })
                } catch (error) {
                    this.msg_form = 'Não foi possível autenticar: ' + error;
                    this.msg_form_type = 'error';
                    this.$recaptcha.reset(widget_id);
                }
            },
            postLogin(response) {
                const auth = {
                    access_token: response.jwt,
                    person_name: response.person_name
                }
                this.$store.commit('setAuth', auth)
                if (this.remember) {
                    Cookie.set('auth', auth, {expires: 7, path: '/'})
                } else {
                    Cookie.set('auth', auth, {path: '/'})
                }
                if (this.$route.query.next) {
                    this.$router.push(this.$route.query.next)
                } else {
                    if (this.$route.path === '/checkout' || this.$route.path === '/checkout/') {
                        window.location.href = this.$route.fullPath;
                    } else {
                        this.$router.push('/')
                    }
                }
            },
            hidePasswordRecovery(val) {
                this.recovery = !val
            },
            updateEmailPasswordRecovery(val) {
                this.email = val
            }
        },
        watch: {
            sending(newvalue) {
                if (newvalue) {
                    this.msg_form = ''
                }
            },
            msg_form(newvalue) {
                if (newvalue !== '') {
                    this.sending = false
                }
            },
            email(newvalue) {
                if (this.registered_email) {
                    this.registered = newvalue === this.registered_email
                    if (newvalue !== this.registered_email) {
                        this.registered = false
                        this.registered_email = ''
                    }
                }
            }
        }
    };
</script>
